import { useEffect, useState } from "react";
import CommonService from "../service/CommonService";
import pathConfig from "../config/PathConfig";

class DbDetail {
  mongo?: Mongo;
}

class Mongo {
  dbname?: string;
}

const Home = () => {
  const [str, setStr] = useState<string>();

  const getStr = async () => {
    var dbDetail = await CommonService.get<DbDetail>(pathConfig.main);
    setStr(dbDetail.mongo?.dbname);
  };

  useEffect(() => {
    getStr();
  });

  return (
    <div>
      <div>This is Home</div>
      <div>Loading {str}</div>
    </div>
  );
};

export default Home;
